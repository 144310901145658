<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-03-11 15:46:37
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-03-14 18:41:47
 * @FilePath: \official_website\src\mobile\pages\products\details\TctReagent.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!-- DNA定量分析诊断试剂  -->
<template>
  <div id="tctReagent">
    <Header></Header>
    <div class="tctReagent-img">
       <Breadcrumb
        :productType="productType"
        :productName="productName"
      ></Breadcrumb>
      <img src="@/assets/image/dnaReagent/dnaReagent.png" alt="" />
      <div class="illustrate-box">
        <div class="title wow animate__animated animate__fadeInUp">
          DNA定量分析诊断试剂
        </div>
        <div class="line"></div>
        <ul class="keywords wow animate__animated animate__fadeInUp">
          <li>灵活</li>
          <li>可靠</li>
          <li>高效</li>
          <li>可控</li>
          <li>稳定</li>
        </ul>
      </div>
    </div>
    <div class="tctReagent-description">
      <div class="parameter">
        <img
          class="wow animate__animated animate__fadeInUp"
          src="@/assets/image/dnaReagent/parameter.png"
          alt=""
        />
      </div>
    </div>
    <RelatedProduct
      v-if="relatProd.length != 0"
      :swiperData="relatProd"
    ></RelatedProduct>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import api from "../../../../api/api";
import Header from "../../../components/Header.vue";
import Footer from "../../../components/Footer.vue";
import Breadcrumb from "../../../components/Breadcrumb.vue";
import RelatedProduct from "../componets/RelatedProduct.vue";
export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
    Breadcrumb,
    RelatedProduct,
  },
  data() {
    return {
      productType: "产品",
      productName: "DNA定量分析诊断试剂",
      relatProd: [],
    };
  },
  async created() {},
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });
  },
  watch: {},
  methods: {},
};
</script>
    
<style lang="scss">
#tctReagent {
  padding-top: 6.5rem;
  .tctReagent-img {
    img {
      width: 100%;
    }
    .illustrate-box {
      padding: 1.25rem;
      .title {
        font-size: 1.5rem;
        font-family: "SourceHanSansSC-Medium";
        margin-bottom: 1.5rem;
      }
      .line {
        width: 4.25rem;
        height: 3px;
        margin-bottom: 1.25rem;
        background: #177a73;
      }
      .keywords {
        display: flex;
        li {
          width: 20%;
          height: 2rem;
          line-height: 2rem;
          margin-right: 4%;
          color: #fff;
          background: #177a73;
          border-radius: 2rem;
          text-align: center;
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }
  .tctReagent-description {
    padding: 1.25rem;
    width: 100%;
    box-sizing: border-box;
    background-image: url("../../../../assets/image/product-detail/des-bg.png");
    background-size: cover;
    .parameter {
      width: 100%;
      img {
        display: block;
        width: 100%;
        margin: auto;
      }
    }
  }
  .footer {
    background: #f5f5f5;
  }
}
</style>